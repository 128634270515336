<template>
  <div
    class="StockStatus rounded-full"
    :class="[ `bg-${stockLabel.color}`, {
      'w-6 h-6 mr-6': isProductCard,
      'w-8 h-8 mr-8': !isProductCard,
    } ]"
  />
  <span
    class="type-xs desk:type-sm"
    :class="{
      'whitespace-nowrap': useShortLabels,
      'desk:type-xs text-dark': isProductCard,
      'desk:type-xs': cartItem,
    }"
    v-html="useShortLabels ? stockLabel.textShort : stockLabel.text"
  />
</template>

<script setup lang="ts">
import { ProductVariantModel } from '~/models/productVariant';
import useStockStatus, { StockLabel } from '~/composeables/useStockStatus';
import { useGlobalContentStore } from '~/store/globalContent';
import { ProductModel } from '~/models/product';
import { CartApiItem } from '~/constants/types/norce';
const globalContent = useGlobalContentStore();

interface Props {
  variant?: ProductVariantModel;
  productItem?: ProductModel
  useShortLabels?: boolean;
  isProductCard?: boolean;
  cartItem?: CartApiItem;
}
const props = withDefaults(defineProps<Props>(), {
  useShortLabels: false,
  isProductCard: false,
});

const { getStockLabel, getStockLabelForCartItem } = useStockStatus();

const alternativeText = computed(()=> {
  const hasCodes = props.productItem.categories.map(m => m.code);
  return globalContent.getAltDeliveryTextCategories.filter(f => hasCodes.includes(f)).length > 0;
});

const stockLabel = computed<StockLabel>(() => {
  if (props.cartItem) {
    return getStockLabelForCartItem(props.cartItem);
  }

  return getStockLabel(props.variant, true, alternativeText.value);
});

</script>
