<template>
  <div
    ref="selectFavorites"
    class="FavoriteDropdown absolute right-6 top-40 bg-lightest shadow
  overflow-auto w-[166px]  max-h-[192px] desk:max-h-[270px]
  px-8 py-4 desk:px-16 desk:py-12
  type-xs desk:type-sm
  z-above"
    :class="{
      'desk:w-[160px]': design !== 'small',
      'desk:w-[180px]': design === 'small',
    }"
  >
    <div v-if="loading">
      <LoadingIcon />
    </div>
    <div v-else-if="loadedProduct">
      <button
        v-for="variant in loadedProduct.variants"
        :key="variant.partNo"
        class="whitespace-nowrap w-full py-4 text-left group/item flex flex-row items-center"
        @click.stop="toggle(variant.partNo)"
      >
        <img
          :src="(isSelected(variant.partNo) || isLoadingToTrue(variant.partNo)) && !isLoadingToFalse(variant.partNo) ? '/icons/heart-filled.svg':'/icons/heart.svg'"
          class="h-20 w-20 inline-block mr-4 desk:mr-8"
          :class="{
            'favoriteAnimateIn': isLoadingToTrue(variant.partNo),
            'opacity-50 group-hover/item:opacity-100 group-hover/item:scale-110 transition-all': !isSelected(variant.partNo),
            'opacity-100': isSelected(variant.partNo),
          }"
          alt=""
        >
        {{ displayName(variant.definingParametrics) }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import Product, { ProductModel } from '~/models/product';
import { onClickOutside } from '@vueuse/core';
import LoadingIcon from '~/components/generic/LoadingIcon.vue';
import useAlgoliaFetch from '~/composeables/useAlgoliaFetch';
import { useUiStore } from '~/store/ui';
import { ParametricData } from '~/constants/types/algolia';
import { useUserStore } from '~/store/user';

const uiStore = useUiStore();

const props = defineProps<{
  product: ProductModel,
  design: 'standard' | 'featured' | 'small' | 'list'
}>();

const emit = defineEmits(['close']);
const selectFavorites = ref<HTMLElement>();
const loading = ref(true); // loading entire product
const { getProductByPartNo } = useAlgoliaFetch();

const loadedProduct = ref<ProductModel | null>(null);
const userStore = useUserStore();

if (props.product.limitedVariantLoaded) {
  const res = await getProductByPartNo(props.product.partNo);
  if (res) {
    loadedProduct.value = Product.create(res.hits);
    loading.value = false;
  } else {
    uiStore.setTemporaryError('Couldn\'t load from algolia');
  }
} else {
  loadedProduct.value = props.product;
  loading.value = false;
}

onClickOutside(selectFavorites, (e) => {
  if (!e.target?.classList?.contains('FavoriteButtonIcon')) {
    emit('close');
  }
});

const displayName = (input: ParametricData[]) => {
  let output = '';
  input.forEach((pd) => {
    if (pd.type === 'list') {
      output += pd.listValue?.name || '';
      output += ' ';
    }
    if (pd.type === 'decimal' || pd.type === 'integer') {
      output += pd.value + ' ' + pd.uom;
      output += ' ';
    }
    if (pd.type === 'multiple') {
      pd.multipleValues?.forEach((mv) => {
        output += mv.name + ' ';
      });
    }
  });
  return output;
};

const isSelected = (partNo: string) => {
  const partNos = userStore.userProfile.favoriteProducts.map((m) => m.id);
  return partNos.includes(partNo);
};

const loadingToTrue = ref<string[]>([]); // while communication with server
const loadingToFalse = ref<string[]>([]);

const toggle = async(partNo: string) => {
  if (isSelected(partNo)) {
    loadingToFalse.value.push(partNo);
    await userStore.updateFavoriteProductQuantity(partNo, 0);
    loadingToFalse.value = loadingToFalse.value.filter(f => f !== partNo);
  } else {
    loadingToTrue.value.push(partNo);
    await userStore.saveFavoriteProduct(partNo);
    loadingToTrue.value = loadingToTrue.value.filter(f => f !== partNo);
  }
};

const isLoadingToTrue = (partNo: string) => {
  return loadingToTrue.value.includes(partNo);
};
const isLoadingToFalse = (partNo: string) => {
  return loadingToFalse.value.includes(partNo);
};

</script>

<style scoped lang="postcss">
</style>
