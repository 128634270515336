<template>
  <div class="FavoriteButton relative">
    <button
      :class="[
        'h-32 w-32 text-center hover:opacity-100 transition-all',
        {
          'opacity-100': anySelected,
          'opacity-55 hover:scale-110': !anySelected && design !== 'cart',
          'border border-light hover:border-darkest group': design === 'cart',
        }]"
      :title="anySelected ? $t('favorite.button.remove'):$t('favorite.button.add')"
      :disabled="loadingToFalse || loadingToTrue"
      @click.stop="onSelect"
    >
      <img
        :src="(anySelected || loadingToTrue) && !loadingToFalse ? '/icons/heart-filled.svg':'/icons/heart.svg'"
        class="FavoriteButtonIcon inline-block"
        :class="{
          'favoriteAnimateIn': (anySelected || loadingToTrue) && !loadingToFalse,
          'w-20 h-20 relative -top-4 -right-4': design === 'small',
          'w-20 h-20 desk:w-24 desk:h-24': design === 'standard',
          'w-16 h-16 opacity-50 group-hover:opacity-100 group-hover:scale-110 transition-all': design === 'cart' && !anySelected,
          'w-16 h-16 opacity-100': design === 'cart' && anySelected,

        }"
        alt=""
      >
    </button>

    <transition name="fade">
      <FavoriteDropdown
        v-if="showDropdown"
        :product="product"
        :design="design"
        @close="showDropdown = false"
      />
    </transition>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from '~/store/user';

import { ref } from 'vue';
import { ProductModel } from '~/models/product';
import FavoriteDropdown from '~/components/product/FavoriteDropdown.vue';

const props = defineProps<{
  product: ProductModel | string; // if possible, use product, otherwise partno (from cart)
  design: 'standard' | 'featured' | 'small' | 'list' | 'cart'
}>();

const userStore = useUserStore();
const loadingToTrue = ref(false);
const loadingToFalse = ref(false);
const showDropdown = ref(false);

/**
 * See if any of the variants have a selected
 */
const anySelected = computed(() => {
  const userFav = userStore.userProfile.favoriteProducts.map((m) => m.id);
  if (typeof props.product === 'string') {
    return userFav.includes(props.product);
  } else {
    return userFav.some((s) => props.product.partNumbers?.includes(s));
  }
});

const onSelect = async() => {
  const partNo = typeof props.product === 'string' ? props.product:props.product.partNo;

  if (typeof props.product !== 'string' && props.product.hasVariants) {
    showDropdown.value = !showDropdown.value;
  } else {
    if (anySelected.value) {
      // remove
      loadingToFalse.value = true;
      await userStore.updateFavoriteProductQuantity(partNo, 0);
      loadingToFalse.value = false;
    } else {
      loadingToTrue.value = true;
      await userStore.saveFavoriteProduct(partNo);
      loadingToTrue.value = false;
    }
  }
};

</script>

<style lang="postcss">
</style>
